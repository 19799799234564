import { createSlice } from '@reduxjs/toolkit'

export interface redirectStore {
    status: string,
    url: string,
    token: string | null,
}

const initialState: redirectStore = {
    status: "checking",
    url: process.env.REACT_APP_RETURN_DEFAULT_URL as string,
    token: null,
}

export const redirectSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setUrl: (state, { payload }) => {
            state.status = "urlReady";
            state.url = payload.url;
        },

    }
})

export const { setUrl } = redirectSlice.actions