import { configureStore } from '@reduxjs/toolkit'
import { loginSlice } from './login/loginSlice'
import { redirectSlice } from './redirect/redirectSlice'



export const store = configureStore({
    reducer: {
        login: loginSlice.reducer,
        redirect: redirectSlice.reducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch