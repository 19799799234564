import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { setUrl } from "./redirectSlice";


export const SetUrlFromQuery = (url: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(setUrl({ url: url }));
    }
}
