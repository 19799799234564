import React, { useState, ChangeEvent, FormEvent } from 'react'
import { isEmail } from '../../functions/EmailVerificator'
import { Box, Paper, TextField, Typography, Button, FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText } from '@mui/material'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { isCode } from '../../functions/CodeVerificator'
import { isPassword } from '../../functions/PasswordVerificator'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import axios from 'axios'
import { LoadingCube } from 'components/commons/LoadingCube'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Swal from 'sweetalert2'

export const ThirdStep = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const myParam = useLocation().search;
    const email = new URLSearchParams(myParam).get("email");
    const code = new URLSearchParams(myParam).get("code");


    const [user, setUser] = useState({
        "email": email,
        "code": code,
        "password": "",
        "confirmPassword": ""
    })
    const [passwordError, setPasswordError] = useState({ state: false, message: "" })
    const [confError, setConfError] = useState({ state: false, message: "" })
    const [showPassword, setShowPassword] = React.useState(false);
    const [reqError, setReqError] = useState({ state: false, message: "" })
    const [reqStatus, setReqStatus] = useState({ state: "", message: "" })


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value })
        setPasswordError({ state: false, message: "" })
        setConfError({ state: false, message: "" })
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isPassword(user.password)) {
            return setPasswordError({
                state: true, message: "La contraseña debe contener al menos 8 caracteres, contener mayúsculas y minúsculas, contener al menos un número y un carácter especial"
            })
        }
        if (user.password != user.confirmPassword) {
            return setConfError({
                state: true, message: "Las contraseñas no coinciden, por favor revise que sean identicas."
            })
        }
        if (code == null || email == null) {
            return setReqError({
                state: true, message: "La solicitud no es valida, vuelva a empezar el proceso."
            })
        }
        if (code.length == 0 || email.length == 0) {
            return setReqError({
                state: true, message: "La solicitud no es valida, vuelva a empezar el proceso."
            })
        }
        //Action Login
        setReqStatus({ state: "loading", message: "Cargando..." })
        // /api/account/recoverpassword
        axios.post(`${process.env.REACT_APP_TAUTH_URL}/api/account/resetpassword`,
            user
        )
            .then((result) => {
                Swal.fire({
                    title: "Contraseña actualizada",
                    text: "Prueba iniciar sesión con tu nueva contraseña",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#ffa400",
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {

                        navigate("/");
                    }
                });
                setReqStatus({ state: "success", message: "Cargando..." })


            }).catch((err) => {
                const errorMessage = err.response.data.errors[0].split("\n")[0].split(": ")[1]
                setReqStatus({ state: "error", message: errorMessage })

            });
        // dispatch(startLoginWithEmailPassword(loginUser));
    };
    if (reqStatus.state == "loading") {
        <LoadingCube />
    }
    if (reqStatus.state == "success") {
        return (
            <Box>
                <Paper elevation={6} sx={{ p: 2, width: { sm: 440, xs: "95vw" }, display: 'flex', alignItems: 'center', flexDirection: "column", gap: 2 }}>
                    <Typography variant='h3' fontWeight={"bold"} textAlign={"center"}>
                        ¡Cambio de constraseña existoso!
                    </Typography>
                    <CheckCircleOutlineIcon sx={{ color: "#ffa400", fontSize: 100 }} />

                    <Button
                        variant="contained"
                        type='submit'
                        onClick={() => { navigate("/login") }}
                    >
                        Iniciar sesión
                    </Button>
                </Paper>
            </Box>
        )

    }
    return (
        <Box>
            <Paper elevation={6} sx={{ p: 2, width: { sm: 440, xs: "95vw" } }}>
                {/* <img src="https://tesseractwebresources.blob.core.windows.net/webimages/Icons/T_v2.png" alt="" /> */}
                <Typography variant='h5' fontWeight={"400"}>
                    Cambio de contraseña
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <FormHelperText
                        className={reqError.state ? 'Mui-error' : ""}
                        sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}
                    >
                        {reqError.message}
                    </FormHelperText>
                </Box>
                <Box component="form" noValidate={false} sx={{ px: 4 }} onSubmit={handleSubmit}>
                    <FormControl sx={{ width: "100%", my: 1 }} variant="standard">
                        <InputLabel required
                            color={passwordError.state ? "error" : "primary"}
                            focused={passwordError.state}>
                            Contraseña
                        </InputLabel>
                        <Input
                            required
                            onChange={handleInputChange}
                            name='password'
                            error={passwordError.state}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText
                            className={passwordError.state ? 'Mui-error' : ""}
                        >
                            {passwordError.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl sx={{ width: "100%", my: 1 }} variant="standard">
                        <InputLabel required
                            color={passwordError.state ? "error" : "primary"}
                            focused={passwordError.state}
                        >
                            Confirmar contraseña
                        </InputLabel>
                        <Input
                            required
                            onChange={handleInputChange}
                            name='confirmPassword'
                            error={confError.state}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText className={confError.state ? 'Mui-error' : ""}  >{confError.state ? confError.message : ""}</FormHelperText>
                    </FormControl>
                    <Box my={2}>
                        <Typography sx={{ fontSize: '0.9rem' }}>
                            {/* ¿No tiene una cuenta? <Link to={'/register'}>Registrate ahora!</Link> */}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Typography sx={{ fontSize: '0.9rem' }} color={"error"}>
                            {reqStatus.state === "error" && reqStatus.message}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            variant="contained"
                            type='submit'
                        >
                            Confirmar cambio
                        </Button>
                    </Box>

                </Box>
            </Paper>
        </Box>
    )
}
