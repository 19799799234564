import { createSlice } from '@reduxjs/toolkit'

export interface userStore {
  status: string,
  registerStatus: string,
  token: string | null,
  email: string,
  username: string
  photoURL: string,
  errorMessage: string | null,
}

const initialState: userStore = {
  status: "checking",
  registerStatus: "notRegistered",
  token: window.localStorage.getItem("jwt"),
  email: "",
  username: "",
  photoURL: "",
  errorMessage: "",
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.status = "loggedIn";
      state.token = payload.token;
      state.email = payload.email;
      state.username = payload.username;
      state.photoURL = payload.photoURL;
      state.errorMessage = payload.message;
      // window.localStorage.setItem("jwt", payload.token);
    },
    logout: (state) => {
      state.status = "notLoggedIn";
      state.token = "";
      state.email = "";
      state.username = "";
      state.photoURL = "";
      window.localStorage.removeItem("jwt");
    },
    checkingCredentials: (state) => {
      state.status = "checking";
    },
    checkingRegister: (state) => {
      state.registerStatus = "checking";
    },
    register: (state) => {
      state.registerStatus = "confirmed";
    }
  }
})

export const { login, logout, checkingCredentials, checkingRegister, register } = loginSlice.actions