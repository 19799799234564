import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/hooks';
import { SetUrlFromQuery } from '../../store/redirect/redirectThunk';
import axios from 'axios';
export const VerifyToken = () => {
    const token = window.localStorage.getItem("jwt");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const url = queryParams.get('url') as string;
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    // useEffect(() => {
    //     if (token) {
    //         //Verify token time
    //         axios.get(`${process.env.REACT_APP_TAUTH_URL}/api/account`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         })
    //             .then(response => {
    //                 const token = response.data.result.jwTtoken
    //                 window.localStorage.setItem("jwt", token);
    //                 if (url)
    //                     window.location.href = `${url}/redirect-login?jwtToken=${token}`
    //                 else {
    //                     window.location.href = `${process.env.REACT_APP_RETURN_DEFAULT_URL as string}/redirect-login?jwtToken=${token}`
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error(error);
    //                 navigate("/login")
    //             });

    //     } else {
    //         //Redirect to login
    //         navigate("/login")
    //     }

    // }, [token])
    useEffect(() => {
        if (url)
            dispatch(SetUrlFromQuery(url))
        navigate("/login")
    }, [url])

    return (
        <div>
        </div>
    )
}
