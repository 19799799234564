import React, { useState, ChangeEvent, FormEvent } from 'react'
import { isEmail } from '../../functions/EmailVerificator'
import { Box, Paper, TextField, Typography, Button, FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText } from '@mui/material'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { isCode } from '../../functions/CodeVerificator'
import axios from 'axios'

export const SecondStep = () => {
    const [user, setUser] = useState({
        code: "",
    })
    const [reqStatus, setReqStatus] = useState({ state: "", message: "" })

    const [codeError, setCodeError] = useState({ state: false, message: "" })
    const navigate = useNavigate()
    const myParam = useLocation().search;
    const email = new URLSearchParams(myParam).get("email");
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value })
        setCodeError({ state: false, message: "" })
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isCode(user.code)) {
            setCodeError({
                state: true, message: "Por favor ingrese un codigo valido."
            })
        } else {
            setReqStatus({ state: "loading", "message": "Cargando..." })
        }


        axios.post(`${process.env.REACT_APP_TAUTH_URL}/api/account/validatecode`, {
            "email": email,
            "recoveryCode": user.code
        })
            .then((result) => {
                navigate(`/recoverpassword?email=${email}&code=${user.code}`)
                setReqStatus({ state: "success", "message": "" })
            }).catch((err) => {
                setCodeError({
                    state: true, message: "Por favor ingrese un codigo valido."
                })
                setReqStatus({ state: "error", "message": "" })

            });
        //Action Login
        // dispatch(startLoginWithEmailPassword(loginUser));
    };
    return (
        <Box>
            <Paper elevation={6} sx={{ p: 2, width: { sm: 440, xs: "95vw" } }}>
                {/* <img src="https://tesseractwebresources.blob.core.windows.net/webimages/Icons/T_v2.png" alt="" /> */}
                <Typography variant='h5' fontWeight={"400"}>
                    Recuperar contraseña
                </Typography>
                <Box component="form" noValidate={false} sx={{ px: 4 }} onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="code"
                        label="Codigo"
                        name="code"
                        autoComplete="none"
                        autoFocus
                        variant="standard"
                        onChange={handleInputChange}
                        helperText={codeError.message}
                        error={codeError.state}
                    />
                    <Box my={2}>
                        <Typography sx={{ fontSize: '0.9rem' }}>
                            ¿No tiene una cuenta? <Link to={'/register'}>Registrate ahora!</Link>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            variant="contained"
                            type='submit'
                        >
                            Verificar codigo
                        </Button>
                    </Box>

                </Box>
            </Paper>
        </Box>
    )
}
