import { Box, ThemeProvider } from '@mui/material'
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { LoginForm } from '../components/login'
import { appTheme } from '../style/AppTheme'
import { RegisterForm } from '../components/register'
import { FirstStep } from '../components/recover/firstStep'
import { SecondStep } from '../components/recover/secondStep'
import { ThirdStep } from '../components/recover/thirdStep'
import { VerifyToken } from '../components/verify'
import { TermsAndConditions } from '../components/terms'
import { ConfirmedAcc } from 'components/confirmed'
import { ErrorPage } from 'components/error'

export const AppRouter = () => {

    return (
        <ThemeProvider theme={appTheme}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh",
                    backgroundImage: "linear-gradient(to right bottom, #ffa400, #ffa284, #ffbdd6, #ffe2ff, #ffffff)",
                }}
            >

                <Routes>

                    <Route path={`/login`} element={<LoginForm />} />
                    <Route path={`/register`} element={<RegisterForm />} />
                    <Route path={`/forgot-password`} element={<FirstStep />} />
                    <Route path={`/forgot-password/code-input`} element={<SecondStep />} />
                    <Route path={`/recoverpassword`} element={<ThirdStep />} />
                    <Route path={`/`} element={<VerifyToken />} />
                    <Route path={`/termsconditions`} element={<TermsAndConditions />} />
                    <Route path={`/accountconfirmation`} element={<ConfirmedAcc />} />
                    <Route path={`/error`} element={<ErrorPage />} />
                    <Route path={`/*`} element={<Navigate to={"/login"} />} />



                </Routes>
            </Box >
        </ThemeProvider>
    )
}
