import React, { useState, ChangeEvent, FormEvent } from 'react'
import { isEmail } from '../../functions/EmailVerificator'
import { Box, Paper, TextField, Typography, Button } from '@mui/material'
import { Link, useNavigate } from "react-router-dom"
import axios from 'axios'
import { LoadingCube } from 'components/commons/LoadingCube'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
export const FirstStep = () => {
    const [user, setUser] = useState({
        email: "",
        // username: ""
    })
    const [emailError, setEmailError] = useState({ state: false, message: "" })
    const [usernameError, setUsernameError] = useState({ state: false, message: "" })
    const [clientError, setClientError] = useState({ state: false, message: "" })
    const [reqStatus, setReqStatus] = useState({ state: "", message: "" })
    const navigate = useNavigate()

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value })
        setEmailError({ state: false, message: "" })
        setUsernameError({ state: false, message: "" })
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // if (user.username.length === 0 && user.email.length === 0) {
        //     return setClientError({
        //         state: true, message: "Al menos debe completar uno de los campos."
        //     })
        // }
        if (user.email.length !== 0 && !isEmail(user.email)) {
            return setEmailError({
                state: true, message: "Por favor ingrese un correo valido."
            })
        }
        setReqStatus({ state: "loading", "message": "Cargando..." })
        axios.post(`${process.env.REACT_APP_TAUTH_URL}/api/account/recoverycode?email=${user.email}`)
            .then((result) => {
                navigate(`/forgot-password/code-input?email=${user.email}`)
                setReqStatus({ state: "success", "message": "" })

            }).catch((err) => {
                // const errorMessage = err.response.data.errors[0].split("\n")[0].split(": ")[1]
                setReqStatus({ state: "error", "message": "" })

            });
        // /api/account/sendrecoverytoken
        // dispatch(startLoginWithEmailPassword(loginUser));
    };
    if (reqStatus.state == "loading") {
        return <LoadingCube />
    }
    else if (reqStatus.state == "success") {
        return (
            <Box>
                <Paper elevation={6} sx={{ p: 2, width: { sm: 440, xs: "95vw" }, display: 'flex', alignItems: 'center', flexDirection: "column", gap: 2 }}>
                    <Typography variant='h3' fontWeight={"bold"} textAlign={"center"}>
                        ¡Correo enviado! Revise su casilla.
                    </Typography>
                    <ForwardToInboxIcon sx={{ color: "#ffa400", fontSize: 100 }} />
                </Paper>
            </Box>
        )

    }
    return (
        <Box>
            <Paper elevation={6} sx={{ p: 2, width: { sm: 440, xs: "95vw" } }}>
                {/* <img src="https://tesseractwebresources.blob.core.windows.net/webimages/Icons/T_v2.png" alt="" /> */}
                <Typography variant='h5' fontWeight={"400"}>
                    Recuperar contraseña
                </Typography>
                <Box component="form" noValidate={false} sx={{ px: 4 }} onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Correo electronico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        variant="standard"
                        onChange={handleInputChange}
                        helperText={emailError.message}
                        error={emailError.state}
                    />
                    {/* <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Nombre de usuario"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        variant="standard"
                        onChange={handleInputChange}
                        helperText={usernameError.message}
                        error={usernameError.state}
                    /> */}
                    <Box my={2}>
                        <Typography sx={{ fontSize: '0.9rem' }}>
                            ¿No tiene una cuenta? <Link to={'/register'}>Registrate ahora!</Link>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Typography sx={{ fontSize: '0.9rem' }} color={"error"}>
                            {reqStatus.state === "error" && reqStatus.message}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            variant="contained"
                            type='submit'
                        >
                            Solicitar codigo
                        </Button>
                    </Box>

                </Box>
            </Paper>
        </Box>
    )

}
