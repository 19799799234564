import React from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
export const ConfirmedAcc = () => {
    const navigate = useNavigate()
    return (
        <Box>
            <Paper elevation={6} sx={{ p: 2, width: { sm: 440, xs: "95vw" }, display: 'flex', alignItems: 'center', flexDirection: "column", gap: 2 }}>
                <Typography variant='h3' fontWeight={"bold"} textAlign={"center"}>
                    ¡Cuenta confirmada!
                </Typography>
                <CheckCircleOutlineIcon sx={{ color: "#ffa400", fontSize: 100 }} />

                <Button
                    variant="contained"
                    type='submit'
                    onClick={() => { navigate("/login") }}
                >
                    Iniciar sesión
                </Button>
            </Paper>
        </Box>
    )
}
