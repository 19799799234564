import React, { useState, CSSProperties } from 'react'
import { Box } from '@mui/material'
import MDEditor from '@uiw/react-md-editor'
import file from "../../addons/TermsAndConditions.md"
import { LoadingCube } from 'components/commons/LoadingCube'

export const TermsAndConditions = () => {
    const [md, setMd] = useState("")
    
    fetch(file)
        .then((result) => {
            result.text()
                .then((text) => {
                    setMd(text)
                })
        }).catch((err) => {

        });
    if (md == "") {
        return (
            <LoadingCube />
        )

    }
    return (
        <Box py={4} data-color-mode="light">
            <MDEditor.Markdown
                source={md}

                style={{ whiteSpace: "pre-wrap", paddingLeft: '2rem', paddingRight: '2rem', paddingTop: "1rem" }}
            />
        </Box>
    )
}
